<template>
  <!--begin::Report Listing-->
  <ListingTemplate
    :customClass="'report-listing'"
    v-if="getPermission('report:view')"
  >
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <v-col cols="6" class="pt-0">
            <h1 class="form-title d-flex margin-auto">
              {{ pageTitle }}
            </h1>
          </v-col>
          <v-col cols="6" class="pt-0 text-right d-flex">
            <v-select
              :items="summaryFilter"
              v-model="listingSearch.duration"
              hide-details
              :disabled="dataLoading || exportLoading"
              :loading="dataLoading || exportLoading"
              color="cyan"
              item-color="cyan"
              class="pt-0 mt-0 custom-appex-filter custom-report-filter"
              item-text="text"
              item-value="value"
              v-on:change="filterRows"
            >
            </v-select>
            <v-btn
              :disabled="dataLoading || exportLoading"
              :loading="dataLoading || exportLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="searchDialog = true"
            >
              <v-icon dark>mdi-filter</v-icon>
            </v-btn>
            <v-btn
              :disabled="dataLoading || exportLoading"
              :loading="dataLoading || exportLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="downloadExcel"
            >
              <v-icon dark>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="goBack"
            >
              <v-icon small left>mdi-keyboard-backspace</v-icon>
              Back
            </v-btn>
          </v-col>
        </template>
      </ListingHeader>
      <template v-if="isSearchEnabled">
        <ReportSearchCriteria
          :dataLoading="dataLoading"
          :allowedSearchFields="searchableArray"
          v-on:clear-search="clearSearch"
          v-on:advanced-search="advancedSearch"
        ></ReportSearchCriteria>
      </template>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 75vh; position: relative"
      >
        <v-container fluid>
          <v-row>
            <v-col md="12">
              <ListingTable
                :columnCount="headerData.length"
                :dataLoading="dataLoading"
                :rowData="rowData"
              >
                <template v-slot:thead>
                  <thead>
                    <tr>
                      <th
                        v-for="(header, index) in headerData"
                        class="simple-table-th"
                        :key="index"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr v-for="(row, rindex) in rowData" :key="rindex">
                      <td
                        v-for="(header, hindex) in headerData"
                        class="simple-table-td cursor-default"
                        :key="hindex"
                      >
                        <template v-if="row[header.key]">
                          <template v-if="header.type == 'date'">
                            {{ formatDate(row[header.key]) }}
                          </template>
                          <template v-else-if="header.type == 'barcode'">
                            <Barcode :barcode="row[header.key]"></Barcode>
                          </template>
                          <template v-else-if="header.type == 'datetime'">
                            {{ formatDateTime(row[header.key]) }}
                          </template>
                          <template v-else>{{ row[header.key] }}</template>
                        </template>
                        <template v-else>
                          <em class="text--secondary">No {{ header.text }}</em>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr v-if="rowData.length <= 0 && !dataLoading">
                      <td :colspan="headerData.length" class="py-4">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no {{ pageTitle.toLowerCase() }} at
                          the moment.
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </ListingTable>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>

      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>

      <Dialog :commonDialog="searchDialog">
        <template v-slot:title>Filter Product</template>
        <template v-slot:body>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 60vh; position: relative"
          >
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index">
                    <template v-if="field.type == 'string'">
                      <v-text-field
                        v-model.trim="listingSearch[field.key]"
                        dense
                        filled
                        hide-details
                        :label="field.text"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <template v-else-if="field.type == 'datetime'">
                      <DateRangePicker
                        v-model="listingSearch[field.key]"
                        :prop_dates="listingSearch[field.key]"
                        :prop_label="field.text"
                      ></DateRangePicker>
                    </template>
                    <template v-else-if="field.type == 'phone'">
                      <PhoneTextField
                        id="primary_phone"
                        v-model="listingSearch[field.key]"
                        :value="listingSearch[field.key]"
                        clearable
                        hide-details
                        @press.enter="filterRows"
                      >
                      </PhoneTextField>
                    </template>
                    <template v-else-if="field.type == 'email'">
                      <v-text-field
                        v-model.trim="listingSearch[field.key]"
                        :rules="[
                          validateRules.validEmail(
                            listingSearch[field.key],
                            field.text
                          ),
                          validateRules.minLength(
                            listingSearch[field.key],
                            field.text,
                            2
                          ),
                          validateRules.maxLength(
                            listingSearch[field.key],
                            field.text,
                            100
                          ),
                        ]"
                        dense
                        filled
                        hide-details
                        :label="field.text"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <v-text-field
                        v-model.trim="listingSearch[field.key]"
                        dense
                        filled
                        hide-details
                        :label="field.text"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </perfect-scrollbar>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="dataLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan white--text"
            v-on:click="filterRows"
          >
            Filter
          </v-btn>
          <v-btn
            :disabled="dataLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="searchDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </ListingTemplate>
  <!--end::Report Listing-->
</template>

<script>
import fileDownload from "js-file-download";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, DOWNLOAD } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import ReportSearchCriteria from "@/view/pages/partials/Report-Search-Criteria.vue";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

export default {
  name: "report",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      paginationPageSize: 0,
      currentPage: 1,
      totalPages: 1,
      dataLoading: false,
      exportLoading: false,
      searchDialog: false,
      rowData: new Array(),
      searchableArray: new Array(),
      dates: new Array(),
      listingSearch: {
        duration: "this_week",
      },
      headerData: new Array(),
      queryParams: new Object(),
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        { text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
    };
  },
  components: {
    PhoneTextField,
    Barcode,
    Dialog,
    ListingTable,
    ListingHeader,
    ListingFooter,
    DateRangePicker,
    ReportSearchCriteria,
    ListingTemplate,
  },
  methods: {
    downloadExcel() {
      const _this = this;
      _this.exportLoading = true;
      let _module = _this.$route.params.module;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "report/" + _module + "/export",
          data: _this.$route.query,
        })
        .then(({ data }) => {
          fileDownload(data, _module + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
    clearSearch() {
      this.listingSearch = {
        duration: this.listingSearch.duration,
      };
      this.queryParams = new Object();
      this.filterRows();
    },
    advancedSearch() {
      this.searchDialog = true;
    },
    getFilterLabel(field) {
      let child = new Array();
      let levelOne = field.split(".");
      let parent = levelOne[0].split("_").join(" ");
      if (levelOne[1]) {
        child.push(levelOne[1].split("_").join(" "));
      }
      if (child.length) {
        let childText = child.join(" ");
        return parent + "[" + childText + "]";
      }
      return this.lodash.replace(parent, "text", "name");
    },
    filterRows() {
      this.listingSearch = this.lodash.pickBy(
        this.listingSearch,
        this.lodash.identity
      );
      if (this.lodash.isEmpty(this.listingSearch) === false) {
        this.lodash.assign(this.queryParams, this.listingSearch);
        this.pushQueryParams();
        this.searchParam = null;
        this.searchDialog = false;
        this.searchEnabled = true;
      }
    },
    setGetParams() {
      const _this = this;
      _this.searchEnabled = false;
      if (_this.lodash.isEmpty(_this.$route.query) === false) {
        let allowedArray = _this.searchableArray.map((row) => {
          return row.key;
        });
        for (let key in _this.$route.query) {
          if (_this.lodash.includes(allowedArray, key)) {
            _this.listingSearch[key] = _this.$route.query[key];
          }
        }
      }
    },
    pushQueryParams() {
      const _this = this;
      if (!_this.lodash.isEqual(_this.$route.query, _this.queryParams)) {
        _this.$router
          .replace(
            _this.getDefaultRoute("report.detail", { query: _this.queryParams })
          )
          .then(() => {
            _this.getReportData();
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.getReportData();
      }
    },
    getReportData() {
      const _this = this;
      _this.dataLoading = true;
      _this.rowData = new Array();
      _this.totalPages = 0;
      _this.currentPage = 0;
      _this.totalRows = 0;
      _this.rowsOffset = 0;
      let _module = _this.$route.params.module;
      let formData = new Object({
        ..._this.listingSearch,
        ..._this.$route.query,
      });
      _this.$store
        .dispatch(QUERY, {
          url: "report/" + _module,
          data: formData,
        })
        .then(({ data }) => {
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.rowData = data.rows;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.searchableArray = data.headers;
          _this.headerData = data.headers;
          _this.listingSearch["duration"] = formData.duration;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.setGetParams();
        });
    },
  },
  computed: {
    isSearchEnabled() {
      let result = false;
      for (let i = 0; i < this.searchableArray.length; i++) {
        if (this.$route.query[this.searchableArray[i].key]) {
          if (
            this.lodash.isArray(this.$route.query[this.searchableArray[i].key])
          ) {
            if (
              this.lodash.isEmpty(
                this.$route.query[this.searchableArray[i].key]
              ) === false
            ) {
              result = true;
              break;
            }
          } else {
            result = true;
            break;
          }
        }
      }
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
    pageTitle() {
      let title = this.$route.params.module;
      if (typeof title == "string") {
        return this.lodash.upperFirst(title.replaceAll("-", " "));
      }
      return null;
    },
  },
  mounted() {
    this.getReportData();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Report",
      },
    ]);
  },
};
</script>
